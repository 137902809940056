import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/ink-stains/post-layout/post-layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`In the year 1997 GeneTree was the first company to provide direct-to-consumer Ancestry Testing. Genealogical DNA testing is a newly developed technology that provides an inexpensive and fast way to learn about genetic heritage through the examination of a customer’s genome. This is done in three ways; Y chromosome, mitochondrial DNA, or autosomal DNA testing. Each test requires only a saliva or blood sample, making testing available to a wide market. The customer base are told through advertising that this is a way of self-discovery. However, one’s culture should be based on commitments, traditions and beliefs, not who their DNA says they are.`}</p>
    <h3 {...{
      "id": "advantages-and-disadvantages-of-each-type-of-ancestry-test",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#advantages-and-disadvantages-of-each-type-of-ancestry-test",
        "aria-label": "advantages and disadvantages of each type of ancestry test permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Advantages and Disadvantages of Each Type of Ancestry Test`}</h3>
    <p>{`Each type of Ancestry Test is relatively unique. Y-DNA testing examines short tandem repeats (STRs) on the Y chromosome. This “is a male-specific DNA test as women do not have a Y chromosome” (FamilyTreeDNA, 2020). Y-DNA tests show male lineage. Thus, the disadvantages of this test are the inability to see the female ancestral line, and the female inability to take the test. Most DNA is stored in chromosomes within the nucleus. The mitochondria contains a bit of its own DNA: called mitochondrial DNA (mtDNA). mtDNA testing is available for both sexes. However, mtDNA is only transferred through female ancestors. Therefore, it will provides information on the female ancestral line. Lastly, autosomal DNA (atDNA) is found on somatic chromosomes (chromosomes other than a sex chromosome). The autosomal DNA test is the most useful, as it is available for both sexes, and shows both sides of your family lineage. Most direct-to-consumer Ancestry tests are this type.`}</p>
    <p>{`A major downfall of all Ancestry tests is that they only identify biological relatives. It is possible to be matched to a blood relative who is not in your family lineage. Companies sometimes keep DNA and sell it “to research diseases by non-profit organizations” (AncestryDNA, 2019). DNA is also sold to third-party, for-profit pharmaceutical companies to create drugs. Ancestry tests work more often than the public assumes. AncestryDNA claims that in 2019 their “test was correct 99% of the time.”`}</p>
    <h3 {...{
      "id": "what-one-can-learn-from-ancestry-testing",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#what-one-can-learn-from-ancestry-testing",
        "aria-label": "what one can learn from ancestry testing permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`What One Can Learn From Ancestry Testing`}</h3>
    <p>{`Ancestry tests tell people their genetic ethnicity and genetic matches. Most customers purchase the product hoping to discover their countries of origin; as this is advertised the most. In 2013, Ted Wood took an atDNA test to learn more about his birth father. Mr. Wood was a sperm donor during college. Although the tests did not identify his father, he learned he had a twenty-seven year old daughter. He did not directly learn about his father through the tests; but, he was reunited with long-lost relatives and pieced together a story of his father’s life. Ted Wood is one of many who matched with others and learned more about their family’s history through ancestry tests.`}</p>
    <p>{`DNA tests will inform one of where they are from and of their genetic matches, but not who they are. In 2020 Georgina Lawton, a writer for the Guardian said, “The test showed that my blackness comes from Nigeria; 43% of my DNA, in fact.” She then reported that she had “no cultural knowledge of Nigeria” and that the tests had “made `}{`[her]`}{` question `}{`[her]`}{` ancestry”. A collaboration between music company Spotify and AncestryDNA created an ad where they will tailor a playlist for you based on consumer’s DNA. This begs the question: Is your DNA related to your culture? For instance, Georgina Lawton found she was Nigerian but she had no cultural relation to her heritage. Lawton has Nigerian ancestors, but she doesn’t take part in any Nigerian holidays, traditions, or other cultural activities. There is no connection. DNA is not deterministic. Another test taker, Kristin Brown claimed in 2018, “DNA testing botched `}{`[her]`}{` family heritage”. In this case, Ms. Brown thought her ancestry was completely different from what it truly is. Brown took four DNA tests by four different companies, each time getting a different result. In summary, DNA tests are useful in some cases, in others they provide incorrect information. When they do work, they still do not tell you who you are.`}</p>
    <h3 {...{
      "id": "conclusion-on-ancestry-testing",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#conclusion-on-ancestry-testing",
        "aria-label": "conclusion on ancestry testing permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Conclusion On Ancestry Testing`}</h3>
    <p>{`Reflecting on myself, my genetic heritage is Asian Indian and American. I spent most of my life abroad in Tunisia and Ukraine. My second language is Russian and more than half of my life was spent on two different continents. Each of these places have made me who I am and my DNA is none the wiser.`}<br parentName="p"></br>{`
`}{`References list`}</p>
    <p>{`1: AncestryDNA® Test Accuracy | AncestryDNA® Learning Hub. (2020). Retrieved 12 February 2020, from `}<a parentName="p" {...{
        "href": "https://www.ancestry.com/lp/genetic-testing/ancestrydna-test-accuracy"
      }}>{`https://www.ancestry.com/lp/genetic-testing/ancestrydna-test-accuracy`}</a></p>
    <p>{`2: Are genetic ancestry tests reinforcing wrongheaded ideas of race?. (2020). Retrieved 6 February 2020, from `}<a parentName="p" {...{
        "href": "https://nationalpost.com/news/world/are-genetic-ancestry-tests-reinforcing-wrongheaded-ideas-of-race"
      }}>{`https://nationalpost.com/news/world/are-genetic-ancestry-tests-reinforcing-wrongheaded-ideas-of-race`}</a></p>
    <p>{`3: How DNA Testing Botched My Family's Heritage, and Probably Yours, Too. (2020). Retrieved 10 February 2020, from `}<a parentName="p" {...{
        "href": "https://gizmodo.com/how-dna-testing-botched-my-familys-heritage-and-probab-1820932637"
      }}>{`https://gizmodo.com/how-dna-testing-botched-my-familys-heritage-and-probab-1820932637`}</a></p>
    <p>{`4: Lawton, G. (2020). ‘It made me question my ancestry’: does DNA home testing really understand race?. Retrieved 10 February 2020, from `}<a parentName="p" {...{
        "href": "https://www.theguardian.com/lifeandstyle/2018/aug/11/question-ancestry-does-dna-testing-really-understand-race"
      }}>{`https://www.theguardian.com/lifeandstyle/2018/aug/11/question-ancestry-does-dna-testing-really-understand-race`}</a></p>
    <p>{`5: Schmidt, S. (2020). 9 Leading Companies in Direct-to-Consumer Genetic Testing. Retrieved 6 February 2020, from `}<a parentName="p" {...{
        "href": "https://blog.marketresearch.com/9-leading-companies-in-direct-to-consumer-genetic-testing"
      }}>{`https://blog.marketresearch.com/9-leading-companies-in-direct-to-consumer-genetic-testing`}</a></p>
    <p>{`6: What is genetic ancestry testing?. (2020). Retrieved 6 February 2020, from `}<a parentName="p" {...{
        "href": "https://ghr.nlm.nih.gov/primer/dtcgenetictesting/ancestrytesting"
      }}>{`https://ghr.nlm.nih.gov/primer/dtcgenetictesting/ancestrytesting`}</a></p>
    <p>{`7: What is Genotyping? | Thermo Fisher Scientific - UK. (2020). Retrieved 6 February 2020, from `}<a parentName="p" {...{
        "href": "https://www.thermofisher.com/ua/en/home/life-science/pcr/real-time-pcr/real-time-pcr-learning-center/genotyping-analysis-real-time-pcr-information/what-is-genotyping.html"
      }}>{`https://www.thermofisher.com/ua/en/home/life-science/pcr/real-time-pcr/real-time-pcr-learning-center/genotyping-analysis-real-time-pcr-information/what-is-genotyping.html`}</a></p>
    <p>{`8: Zhang, S. (2020). Your DNA Is Not Your Culture. Retrieved 10 February 2020, from `}<a parentName="p" {...{
        "href": "https://www.theatlantic.com/science/archive/2018/09/your-dna-is-not-your-culture/571150"
      }}>{`https://www.theatlantic.com/science/archive/2018/09/your-dna-is-not-your-culture/571150`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      